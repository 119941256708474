import styled from "styled-components";

type SizedBoxProps = {
    width?: number,
    height?: number
}

export const SizedBox = styled.div<SizedBoxProps>`
    height: ${props => props.height || 0}px;
    width: ${props => props.width || 0}px;
`;
