import queryString from "query-string";
import { useLocation } from 'react-router-dom';

export const useQueryParam = (name: string): string | null => {

    const location = useLocation();
    const qs = queryString.parse(location.search);

    const value = qs[name];

    return typeof value === "string" ? value : null;
}