import { motion } from "framer-motion";
import React from "react";

import { Container } from "../assessment-page/styles";

export const AssessmentSuccessPage = () => {
    return (
        <motion.section className="content apc standard-components star-bg">
            <div className="container">
                <div className="row">
                    <div className="info flex-col align-center">
                        <h2 className="heading main c3-text">Thank you!</h2>
                        <h4>You've completed your assessment</h4>
                    </div>
                    <br/>
                    <Container>
                        <p className="text-center">We have securely stored your information and this is being analysed and shared with your Lender / Assessor who will be in touch within 14 days.</p>
                    </Container>
                </div>
            </div>
        </motion.section>
    );
};