import clsx from "clsx";
import {FormikHelpers, useFormik} from "formik";
import {motion} from "framer-motion";
import React, {useState} from "react";
import { useHistory } from "react-router-dom";

import {QuestionModel} from "../../../models/service/question/question-model";
import {AssessmentService} from "../../../services/assessment-service";
import {buildFormikConfig} from "../../helpers/formik-builder";
import {Form} from "./styles";

const formControlVariants = {
    initial: { opacity: 0, y: 20 },
    enter: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
};

type AssessmentFormProps = {
    questions: QuestionModel[],
    assessmentCode: string
}

export const AssessmentForm = ({ questions, assessmentCode}: AssessmentFormProps) => {

    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleFormikSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
        setIsSubmitting(true);

        const answers = Object.keys(values).map(key => ({ questionId: key, questionOptionId: values[key] }));

        AssessmentService.submit({ assessmentCode, answers })
            .then(() => {
                history.push("/assessment/success");
            })
            .catch(error => {
                history.push(`/assessment/error?error=${error}`);
            })
    };

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched
    } = useFormik(buildFormikConfig(questions, handleFormikSubmit));

    return (
        <Form onSubmit={handleSubmit}>
            {Object.keys(values).map(key => {
                const question = questions.find(x => x.id.toString() === key);
                return question && (
                    <motion.div
                        key={question.id}
                        className={clsx("col-xs-12", { "has-error": !!errors[key] && !!touched[key] })}
                        initial="exit"
                        animate="enter"
                        exit="exit"
                        variants={formControlVariants}
                        style={{marginBottom: "2rem"}}>
                        <label htmlFor={key} className="control-label">{question.text} <em className="req">*</em></label>
                        {!question.isFreeText && (
                            <div className="form-group dropdown">
                                <select
                                    className="form-control"
                                    id={key}
                                    name={key}
                                    onChange={handleChange}
                                    onBlur={handleBlur}>
                                    <option value="null">- Please select -</option>
                                    {question.questionOptions.map(option => (
                                        <option key={option.id} value={option.id}>{option.text}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {question.isFreeText && (
                            <div className="form-group">
                                <input 
                                    className="form-control"
                                    type="number"
                                    min={question.minValue}
                                    max={question.maxValue}
                                    id={key} 
                                    name={key} 
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder={question.prompt}/>
                            </div>
                        )}
                        <div className="input-validation-error">{errors[key]}</div>
                    </motion.div>
                );
            })}
            <div className="flex-row justify-center">
                <input type="submit" className={clsx("btn c1-bg c5-text", { "disabled": isSubmitting })} value="Submit"/>
            </div>
        </Form>
    );
};