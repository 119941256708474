import styled from 'styled-components';

const image = require("../../../../public/images/star.svg");
export const Container = styled.div.attrs({ className: "container" })`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: contain;
`;