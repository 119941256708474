import {motion} from "framer-motion";
import React from "react";
import { useHistory } from "react-router-dom";

import {SizedBox} from "../../components/sized-box";
import {useQueryParam} from "../../hooks/useQueryParam";
import { Container } from "../assessment-page/styles";

export const AssessmentErrorPage = () => {

    const history = useHistory();
    const error = useQueryParam("error");

    return (
        <motion.section className="content apc standard-components">
            <div className="container">
                <div className="info flex-col align-center">
                    <h2 className="heading main c3-text">Oops!</h2>
                    <h4>Looks like something has gone wrong</h4>
                </div>
                <br/>
                <Container>
                    <div className="flex-col align-center">
                        <h5>Please try again</h5>
                        { error && <p>{ error }</p> }
                        <button className="btn c1-bg c5-text" onClick={() => history.goBack()}>Back</button>
                    </div>
                </Container>
            </div>
        </motion.section>
    );
};