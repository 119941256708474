import { motion } from "framer-motion";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Redirect, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import { Config } from './../../../app-config';
import { AssessmentService } from './../../../services/assessment-service';
import { SizedBox } from './../../components/sized-box';
import { useQueryParam } from './../../hooks/useQueryParam';

const Heading = styled.p`
    font-size: 24px !important;
`;

const SubmitButton = styled.button<{ disabled: boolean }>`
    ${props => props.disabled && css`
        cursor: not-allowed;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none;
        opacity: .65;
    `}
`;

export const StartPage: React.FC = () => {

    const secret = useQueryParam("secret");

    if (!secret) return <Redirect to="/not-found" />;

    const history = useHistory();
    const [captcha, setCaptcha] = useState<string | null>(null);
    const [clientName, setClientName] = useState<string>("");
    const [year, setYear] = useState<string>("");
    const [quarter, setQuarter] = useState<string>("");
    const [error, setError] = useState<string>("");

    const handleCaptcha = (response: string | null) => {
        setCaptcha(response);
    };

    const currentYear = new Date().getFullYear();

    const handleSubmit = async () => {
        if (!captcha || !clientName || !year || !quarter) return;

        try {
            const response = await AssessmentService.create(captcha, clientName, secret, year, quarter);
            history.push(`/assessment?id=${response.code}`);
        } catch (error) {
            setError(error);
        }
    };

    return (
        <motion.section className="content apc standard-components" initial="exit" animate="enter" exit="exit">
            <div className="container">
                <div className="row">
                    <div className="info col-xs-12">
                        <h2 className="heading main c3-text"><span className="line">Welcome to GFA Exchange</span></h2>
                    </div>
                    <SizedBox height={32} className="col-xs-12" />
                    <div className="inner c5-bg base-text has-borders c6-borders col-xs-12">
                        <Heading className="heading secondary text-center">
                            <span className="line">Your Assessment</span>
                        </Heading>
                        <SizedBox height={32} />
                        <div className="row">
                            <div className="form-group col-md-6 col-md-offset-3 col-xs-12">
                                <label htmlFor="ClientOrEmail" className="control-label">
                                    Your Client Reference - Please type exactly as sent to you<em className="req">*</em>
                                </label>
                                <input
                                    className="form-control"
                                    id="ClientOrEmail"
                                    name="ClientOrEmail"
                                    placeholder="Client name or Email"
                                    type="text"
                                    onChange={e => setClientName(e.target.value)} />
                                <label htmlFor="YearOfAssessment" className="control-label">
                                    Please provide the year for this assessment<em className="req">*</em>
                                </label>
                                <input
                                    className="form-control"
                                    id="Year"
                                    name="Year"
                                    placeholder="Year for assessment"
                                    type="number" min="1975" max={currentYear}
                                    onChange={e => setYear(e.target.value)}
                                />
                                <label htmlFor="QuarterOfAssessment" className="control-label">
                                    Please provide the quarter for this assessment<em className="req">*</em>
                                </label>
                                <input
                                    className="form-control"
                                    id="Quarter"
                                    name="Quarter"
                                    placeholder="Quarter for assessment"
                                    type="number" min="1" max="4"
                                    onChange={e => setQuarter(e.target.value)}
                                />

                                <SizedBox height={8} />
                                <ReCAPTCHA sitekey={Config.recaptchaSiteKey} onChange={handleCaptcha} />
                                <SizedBox height={32} />
                                <SubmitButton
                                    disabled={!captcha}
                                    className="btn c1-bg c5-text"
                                    onClick={handleSubmit}>
                                    Let's Assess!
                                </SubmitButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.section>
    );
};