import { motion } from "framer-motion";
import React from "react"

const twitter = require("../../../../../public/images/twitter.png");
const linkedIn = require("../../../../../public/images/linkedin.png");

export const Footer = React.memo(() => {
    return (
        <motion.footer positionTransition exit={{ opacity: 1 }} key="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-xs-12">
                        <div className="company-details">
                            <p className="heading">GFA Exchange</p>
                            <address>
                                <p>Growth Finance Analytics Ltd t/a GFA Exchange<br />Avebury House<br />55 Newhall Street<br />Birmingham<br />B3 3RB</p>
                            </address>
                            <p>Email. <a href="mailto:office@gfaexchange.com">office@gfaexchange.com</a></p>
                        </div>
                    </div>
                    <div className="col-md-3 col-xs-12 pull-right">
                        <nav className="social">
                            <p className="heading">Social pages</p>
                            <ul>
                                <li>
                                    <a href="https://twitter.com/gfaexchange" title="Link will open in a new window/tab" target="_blank">
                                        <img src={twitter} alt="Twitter" />
                                        <span className="hidden">Twitter</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/15236479" title="Link will open in a new window/tab" target="_blank">
                                        <img src={linkedIn} alt="LinkedIn" />
                                        <span className="hidden">LinkedIn</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <nav className="footer">
                            <ul>
                                <li className="copyright"><div>© 2020</div></li>

                                <li>
                                    <a href="/privacy-policy/">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/terms-and-conditions/">Terms and Conditions</a>
                                </li>
                                <li>
                                    <a href="/sitemap/">Sitemap</a>
                                </li>

                                <li className="site-credit">
                                    <div>Development by </div>
                                    <a target="_blank" title="Link will open in a new window/tab" href="http://www.codewizards.co.uk"><span>Code Wizards</span></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </motion.footer>
    );
});