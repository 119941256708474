import { AnimatePresence } from "framer-motion";
import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { DefaultLayoutRoute } from './layout/DefaultLayoutRoute';
import { AssessmentPage } from './pages/assessment-page';
import { NotFoundPage } from './pages/not-found-page';
import { OtherPage } from './pages/other-page';
import { StartPage } from "./pages/start-page";
import {AssessmentSuccessPage} from "./pages/assessment-success-page";
import {AssessmentErrorPage} from "./pages/assessment-error-page";

export const Routes: React.FC = () => {
    return (
        <BrowserRouter>
            <Route
                render={({ location }) => (
                    <AnimatePresence exitBeforeEnter initial={false}>
                        <Switch location={location} key={location.pathname} >
                            <DefaultLayoutRoute exact path="/" component={StartPage} />
                            <DefaultLayoutRoute exact path="/other" component={OtherPage} />
                            <DefaultLayoutRoute exact path="/assessment" component={AssessmentPage} />
                            <DefaultLayoutRoute exact path="/assessment/success" component={AssessmentSuccessPage} />
                            <DefaultLayoutRoute exact path="/assessment/error" component={AssessmentErrorPage} />
                            <DefaultLayoutRoute exact path="/not-found" component={NotFoundPage} />
                            <DefaultLayoutRoute exact path="*" component={NotFoundPage} />
                        </Switch>
                    </AnimatePresence>
                )}
            />
        </BrowserRouter>
    );
};