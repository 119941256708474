import styled from "styled-components";

export const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

export const Container = styled.div.attrs({ className: "inner c5-bg base-text col-lg-8 col-lg-offset-2 col-sm-10 col-sm-offset-1 col-xs-12" })`
    border-radius: 20px;
    padding-top: 4rem;
    box-shadow: 20px 20px 50px rgba(0,0,0,0.1);
`;