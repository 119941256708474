import { Config } from './../app-config';
import { QuestionModel } from '../models/service/question/question-model';

const endpoints = {
    questions: "/api/assessments/"
};

export const QuestionsService = {
    getQuestions: async (assessmentId: string): Promise<QuestionModel[]> => {
        const response = await fetch(`${Config.cmsBaseUrl}${endpoints.questions}${assessmentId}/questions`);

        return await response.json() as QuestionModel[];
    }
};