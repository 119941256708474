import { motion } from "framer-motion";
import React from "react"
import { Link } from "react-router-dom";

export const OtherPage: React.FC = () => {
    return (
        <motion.section className="content apc standard-components c5-bg"  initial="exit" animate="enter" exit="exit">
            <div className="container">
                <div className="row">
                    <div className="info col-md-8 col-xs-12">
                       Hello!
                    </div>
                </div>
                <div className="row">
                    <div className="info col-md-8 col-xs-12">
                        <h2 className="heading main c3-text"><span className="line">We help finance providers add value</span></h2>
                        <Link to="/other">Other</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="info col-md-8 col-xs-12">
                        <h2 className="heading main c3-text"><span className="line">We help finance providers add value</span></h2>
                        <Link to="/other">Other</Link>
                    </div>
                </div>
            </div>
        </motion.section>
    );
};