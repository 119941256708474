import React, { memo } from "react";
import { Link } from "react-router-dom";

export const Header = memo(() => {

    return (
        <header>
            <div className="container">
                <div className="row">
                    <div id="logo"><Link to="/">GFA Exchange </Link></div>
                    <a className="expand"><span>Menu</span></a>
                </div>
            </div>
        </header>
    );
});