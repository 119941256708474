import { Config } from './../app-config';
import { AssessmentCreatedModel } from './../models/service/assessment/assessment-created-model';
import {CreateAssessmentAnswersModel} from "../models/service/assessment/create-assessment-answers-model";

const endpoints = {
    create: "/api/assessments",
    submit: "/api/answers"
};

export const AssessmentService = {   

    create: async (captcha: string, clientName: string, secret: string, year: number, quarter: number): Promise<AssessmentCreatedModel> => {
        const response = await fetch(`${Config.cmsBaseUrl}${endpoints.create}`, {
            method: "POST",
            body: JSON.stringify({
                captcha,
                clientName,
                secret,
                year,
                quarter
            }),
            headers: [
                [ "Content-Type", "application/json" ],
                [ "accept", "application/json" ]
            ]
        });

        if(!response.ok) {
            const message = await response.text();
            throw new Error(message);
        }
        
        return await response.json() as AssessmentCreatedModel;   
    },

    submit: async (model: CreateAssessmentAnswersModel): Promise<void> => {
        const response = await fetch(`${Config.cmsBaseUrl}${endpoints.submit}`, {
            method: "POST",
            body: JSON.stringify(model),
            headers: [
                [ "Content-Type", "application/json" ],
                [ "accept", "application/json" ]
            ]
        });

        if(!response.ok) {
            const message = await response.text();
            throw new Error(message);
        }
    }
};