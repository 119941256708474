import {QuestionModel} from "../../models/service/question/question-model";
import {FormikConfig, FormikHelpers} from "formik";
import * as yup from "yup";
import {ObjectSchemaDefinition} from "yup";

export const buildFormikConfig = (questions: QuestionModel[], onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void): FormikConfig<any> => {

    const initialValues: any = {};
    const schema: ObjectSchemaDefinition<any> = {};

    for(let question of questions) {
        initialValues[question.id.toString()] = "null";

        if(question.isFreeText) {
            schema[question.id.toString()] = yup
                .number()
                .required(`This question is required`)
                .notOneOf(["null"], `This question is required`)
                .min(question.minValue)
                .max(question.maxValue - 1)
                .label("Your answer");
        } else {
            schema[question.id.toString()] = yup
                .string()
                .required(`This question is required`)
                .notOneOf(["null"], `This question is required`);
        }
    }

    return {
        initialValues,
        onSubmit,
        validationSchema: yup.object().shape(schema)
    };
};