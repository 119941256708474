import * as React from "react";
import ReactDOM from "react-dom";

import { Routes } from "./routes";

const App = () => {

    return (
        <Routes/>
    );
};

const element = document.getElementById("app");
ReactDOM.render(<App/>, element);