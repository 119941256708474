import React from "react"

import { Container } from './styles';

export const NotFoundPage: React.FC = () => {
    return (
        <Container>
            <div className="row">
                <div className="info col-xs-12">
                    <h2 className="heading main c3-text">Page not found!</h2>
                </div>
            </div>
        </Container>
    );
};