import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import { QuestionModel } from '../../../models/service/question/question-model';
import { QuestionsService } from "../../../services/questions-service";
import { SizedBox } from "../../components/sized-box";
import { useQueryParam } from '../../hooks/useQueryParam';
import {AssessmentForm} from "./assessment-form";
import { Container } from './styles';

export const AssessmentPage = () => {

    const assessmentId = useQueryParam("id");
    const [questions, setQuestions] = useState<QuestionModel[] | null>(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (assessmentId) {
            QuestionsService
                .getQuestions(assessmentId)
                .then(setQuestions)
                .catch(() => {
                    setError(true);
                });
        }
    }, []);

    if (assessmentId === null) {
        return <Redirect to="/" />;
    }

    return (
        <motion.section className="content apc standard-components wave-bg">
            <div className="container">
                <div className="row">
                    <div className="info col-lg-8 col-lg-offset-2 col-sm-10 col-sm-offset-1 col-xs-12 flex-col align-center">
                        <div className="secondary-heading">Assessment</div>
                        <h2 className="heading main c3-text text-center">Let's get assessed</h2>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <Container>
                        {questions && <AssessmentForm questions={questions} assessmentCode={assessmentId}/>}
                        {error && (
                            <h5 className={"text-center"}>Oops! Something appears to have gone wrong.</h5>
                        )}
                    </Container>
                </div>
            </div>
        </motion.section>
    )
};