import { motion } from "framer-motion";
import React from "react"
import { Route, RouteProps } from "react-router-dom";
import styled from "styled-components";

import { Footer } from "./default/footer";
import { Header } from "./default/header"

const Body = styled(motion.div)`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const DefaultLayoutRoute: React.FC<RouteProps> = ({ component, ...rest }: RouteProps) => {
    return (
        <Route {...rest} render={() => (
            <>
                <Header/>
                <Body initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    { component && React.createElement(component, {}) }
                </Body>
                <Footer />
            </>
        )} />
    );
};